const accMixin = {
  data() {
    return {
      subscriptionPurchased: false
    };
  },
  computed: {
    planName: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.name;
    }
  },
  mounted() {
    if (
      this.$route.query.idCliente != null &&
      this.$route.query.identificador != null &&
      this.$route.query.planDuotalk != null
    ) {
      this.updateAccFinancial();
    }
  },
  methods: {
    updateAccFinancial() {
      var self = this;
      var triggerPlanChange = setInterval(function () {
        if (self.$store.state.acc.current_acc.accPlan.id !== 0) {
          self.updateAccPlan(
            self.$route.query.planDuotalk
          );
          clearInterval(triggerPlanChange);
        }
      }, 20);

      this.$http({
        method: "POST",
        url: `/g/acc/plan/financial/${this.$store.state.acc.current_acc.id}`,
        data: {
          superlogicaIdCliente: this.$route.query.idCliente,
          superlogicaId: this.$route.query.identificador,
          plan: this.$route.query.planDuotalk
        }
      })
        .then(() => {
          this.subscriptionPurchased = true;
        })
        .catch(error => {
          if (error.request != null)
            if (typeof error.request.response !== "undefined")
              error.message =
                JSON.parse(error.request.response).data != null
                  ? Array.isArray(JSON.parse(error.request.response).data)
                    ? JSON.parse(error.request.response).data[0].msg
                    : ""
                  : JSON.parse(error.request.response).message;
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-center",
            color: "warning",
            time: 4000
          });
        });
    },
    updateAccPlan(plan) {
      var payload = {
        id: this.$store.state.acc.current_acc.accPlan.id,
        acc: this.$store.state.acc.current_acc.id,
        plan: plan
      };

      this.$store
        .dispatch("acc/updateAccPlan", payload)
        .then(() => {
          this.subscriptionPurchased = true;
          this.$vs.notify({
            time: 2500,
            title: this.$t("Ok"),
            text: this.$t("AccPlanEdited"),
            iconPack: "feather",
            icon: "icon-success-circle",
            position: "top-center",
            color: "success"
          });
        })
        .catch(error => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;

          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-center",
            color: "danger"
          });
        });
    }
  }
};

const inboxMixin = {
  methods: {
    dispatchUpdateActiveConversation(componentName, eventName, ...rest) {
      let parent = this.$parent || this.$root
      let name = parent.$options.name

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent
        if (parent) {
          name = parent.$options.name
        }
      }
      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(rest))
      }
    }
  }
}

export { inboxMixin, accMixin }