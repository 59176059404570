var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.subscriptionPurchased)?_c('div',[_c('div',{staticClass:"vx-col w-full mb-base"},[_c('vs-alert',{attrs:{"active":_vm.subscriptionPurchased,"closable":"","close-icon":"cancel","color":"success"},on:{"update:active":function($event){_vm.subscriptionPurchased=$event}}},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("SubscriptionSuccessfull", [this.planName]))+" ")])])],1)]):_vm._e(),_c('div',{staticClass:"vx-row justify-center"},[_c('div',{staticClass:"flex flex-wrap justify-center items-center"},[_c('h2',{staticClass:"text-center w-full"},[_vm._v(_vm._s(_vm.$t("TitlePagePro")))])])]),_c('div',{staticClass:"vx-row justify-center"},[_c('div',{staticClass:"flex justify-center flex-col-reverse md:flex-row lg:flex-row xl:flex-row"},_vm._l((_vm.plansOptions),function(currentPlan){return _c('div',{key:currentPlan._id,staticClass:"bg-card-header mt-8 w-full sm:mt-8 md:mt-24 lg:mt-24 xl:mt-24 mr-5"},[_c('vx-card',{staticClass:"shadow-xl"},[_c('div',{staticClass:"p-6 text-center bg-primary",attrs:{"slot":"no-body"},slot:"no-body"},[_c('h1',{staticClass:"text-white font-bold"},[_vm._v(_vm._s(currentPlan.title))])]),_c('h2',{staticClass:"text-center mt-2 font-bold"},[_vm._v(" R$ "+_vm._s(currentPlan.price)+" /mês ")]),_c('vs-row',{attrs:{"vs-justify":"center"}},[(_vm.currentAccPlan.id == currentPlan._id)?_c('vs-button',{staticClass:"mt-8 ml-4 mr-4 mb-1 w-full",attrs:{"color":"warning","type":"relief","to":{
                name: 'page-plans-checkout',
                params: { plan: currentPlan._id },
              },"disabled":_vm.currentAcc.accIsPaying}},[_vm._v(_vm._s(_vm.$t("ChoosedPlan"))+_vm._s(!_vm.currentAcc.accIsPaying ? ". " + _vm.$t("SubscribePlanNow") : ""))]):(!_vm.currentAcc.accIsPaying)?_c('vs-button',{staticClass:"mt-8 ml-4 mr-4 mb-1 w-full",attrs:{"color":"warning","type":"relief","target":"","to":{
                name: 'page-plans-checkout',
                params: { plan: currentPlan._id },
              }}},[_vm._v(_vm._s(_vm.$t("SubscribePlan")))]):_c('vs-button',{staticClass:"mt-8 ml-4 mr-4 mb-1 w-full",attrs:{"color":"warning","type":"relief"},on:{"click":function($event){return _vm.updateAccPlan(currentPlan._id)}}},[_vm._v(_vm._s(!_vm.trialElegible ? _vm.$t("SubscribePlan") : _vm.$t("ButtonTryPro")))]),(
                _vm.currentAccPlan.id == currentPlan._id &&
                _vm.accPlanData.trialDays > 1
              )?_c('span',[_vm._v(_vm._s(_vm.$t("TrialDaysLeft", [ _vm.accPlanData.trialDays, _vm.accPlanData.trialDays > 1 ? _vm.$t("Days") : _vm.$t("Day"), ]))+" "+_vm._s(_vm.$t("Free")))]):_vm._e()],1),_vm._l((currentPlan.planDescription),function(currentPlanDesc,i){return _c('div',{key:i,staticClass:"text-left px-6"},[_c('vs-divider',{staticClass:"my-8 text-xl",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t(currentPlanDesc.title)))]),_c('ul',_vm._l((currentPlanDesc.items),function(currentPlanDescItems,j){return _c('li',{key:j,staticClass:"mb-3 text-base"},[_c('vs-icon',{attrs:{"icon":"done","color":"success","size":"small"}}),_vm._v(" "+_vm._s(_vm.$t(currentPlanDescItems))+" ")],1)}),0)],1)}),_c('vs-row',{attrs:{"vs-justify":"center"}},[(_vm.currentAccPlan.id == currentPlan._id)?_c('vs-button',{staticClass:"mt-8 ml-4 mr-4 mb-1 w-full",attrs:{"color":"warning","type":"relief","to":{
                name: 'page-plans-checkout',
                params: { plan: currentPlan._id },
              },"disabled":_vm.currentAcc.accIsPaying}},[_vm._v(_vm._s(_vm.$t("ChoosedPlan"))+_vm._s(!_vm.currentAcc.accIsPaying ? ". " + _vm.$t("SubscribePlanNow") : ""))]):(!_vm.currentAcc.accIsPaying)?_c('vs-button',{staticClass:"mt-8 ml-4 mr-4 mb-1 w-full",attrs:{"color":"warning","type":"relief","target":"","to":{
                name: 'page-plans-checkout',
                params: { plan: currentPlan._id },
              }}},[_vm._v(_vm._s(_vm.$t("SubscribePlan")))]):_c('vs-button',{staticClass:"mt-8 ml-4 mr-4 mb-1 w-full",attrs:{"color":"warning","type":"relief"},on:{"click":function($event){return _vm.updateAccPlan(currentPlan._id)}}},[_vm._v(_vm._s(!_vm.trialElegible ? _vm.$t("SubscribePlan") : _vm.$t("ButtonTryPro")))]),(
                _vm.currentAccPlan.id == currentPlan._id &&
                _vm.accPlanData.trialDays > 1
              )?_c('span',[_vm._v(_vm._s(_vm.$t("TrialDaysLeft", [ _vm.accPlanData.trialDays, _vm.accPlanData.trialDays > 1 ? _vm.$t("Days") : _vm.$t("Day"), ]))+" "+_vm._s(_vm.$t("Free")))]):_vm._e()],1)],2)],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }