<template>
  <div>
    <div v-if="subscriptionPurchased">
      <div class="vx-col w-full mb-base">
        <vs-alert
          :active.sync="subscriptionPurchased"
          closable
          close-icon="cancel"
          color="success"
        >
          <div class="text-center">
            {{ $t("SubscriptionSuccessfull", [this.planName]) }}
          </div>
        </vs-alert>
      </div>
    </div>
    <div class="vx-row justify-center">
      <div class="flex flex-wrap justify-center items-center">
        <h2 class="text-center w-full">{{ $t("TitlePagePro") }}</h2>
      </div>
    </div>
    <div class="vx-row justify-center">
      <div
        class="
          flex
          justify-center
          flex-col-reverse
          md:flex-row
          lg:flex-row
          xl:flex-row
        "
      >
        <div
          class="
            bg-card-header
            mt-8
            w-full
            sm:mt-8
            md:mt-24
            lg:mt-24
            xl:mt-24
            mr-5
          "
          v-for="currentPlan in plansOptions"
          :key="currentPlan._id"
        >
          <vx-card class="shadow-xl">
            <div slot="no-body" class="p-6 text-center bg-primary">
              <h1 class="text-white font-bold">{{ currentPlan.title }}</h1>
            </div>
            <h2 class="text-center mt-2 font-bold">
              R$ {{ currentPlan.price }} /mês
            </h2>
            <vs-row vs-justify="center">
              <vs-button
                v-if="currentAccPlan.id == currentPlan._id"
                color="warning"
                class="mt-8 ml-4 mr-4 mb-1 w-full"
                type="relief"
                :to="{
                  name: 'page-plans-checkout',
                  params: { plan: currentPlan._id },
                }"
                :disabled="currentAcc.accIsPaying"
                >{{ $t("ChoosedPlan")
                }}{{
                  !currentAcc.accIsPaying ? ". " + $t("SubscribePlanNow") : ""
                }}</vs-button
              >
              <vs-button
                v-else-if="!currentAcc.accIsPaying"
                color="warning"
                class="mt-8 ml-4 mr-4 mb-1 w-full"
                type="relief"
                target
                :to="{
                  name: 'page-plans-checkout',
                  params: { plan: currentPlan._id },
                }"
                >{{ $t("SubscribePlan") }}</vs-button
              >
              <vs-button
                color="warning"
                class="mt-8 ml-4 mr-4 mb-1 w-full"
                type="relief"
                @click="updateAccPlan(currentPlan._id)"
                v-else
                >{{
                  !trialElegible ? $t("SubscribePlan") : $t("ButtonTryPro")
                }}</vs-button
              >
              <span
                v-if="
                  currentAccPlan.id == currentPlan._id &&
                  accPlanData.trialDays > 1
                "
                >{{
                  $t("TrialDaysLeft", [
                    accPlanData.trialDays,
                    accPlanData.trialDays > 1 ? $t("Days") : $t("Day"),
                  ])
                }}
                {{ $t("Free") }}</span
              >
            </vs-row>
            <div
              class="text-left px-6"
              v-for="(currentPlanDesc, i) in currentPlan.planDescription"
              :key="i"
            >
              <vs-divider color="primary" class="my-8 text-xl">{{
                $t(currentPlanDesc.title)
              }}</vs-divider>
              <ul>
                <li
                  class="mb-3 text-base"
                  v-for="(currentPlanDescItems, j) in currentPlanDesc.items"
                  :key="j"
                >
                  <vs-icon icon="done" color="success" size="small"></vs-icon>
                  {{ $t(currentPlanDescItems) }}
                </li>
              </ul>
            </div>
            <vs-row vs-justify="center">
              <vs-button
                color="warning"
                class="mt-8 ml-4 mr-4 mb-1 w-full"
                type="relief"
                :to="{
                  name: 'page-plans-checkout',
                  params: { plan: currentPlan._id },
                }"
                :disabled="currentAcc.accIsPaying"
                v-if="currentAccPlan.id == currentPlan._id"
                >{{ $t("ChoosedPlan")
                }}{{
                  !currentAcc.accIsPaying ? ". " + $t("SubscribePlanNow") : ""
                }}</vs-button
              >
              <vs-button
                color="warning"
                class="mt-8 ml-4 mr-4 mb-1 w-full"
                type="relief"
                target
                :to="{
                  name: 'page-plans-checkout',
                  params: { plan: currentPlan._id },
                }"
                v-else-if="!currentAcc.accIsPaying"
                >{{ $t("SubscribePlan") }}</vs-button
              >
              <vs-button
                color="warning"
                class="mt-8 ml-4 mr-4 mb-1 w-full"
                type="relief"
                @click="updateAccPlan(currentPlan._id)"
                v-else
                >{{
                  !trialElegible ? $t("SubscribePlan") : $t("ButtonTryPro")
                }}</vs-button
              >
              <span
                v-if="
                  currentAccPlan.id == currentPlan._id &&
                  accPlanData.trialDays > 1
                "
                >{{
                  $t("TrialDaysLeft", [
                    accPlanData.trialDays,
                    accPlanData.trialDays > 1 ? $t("Days") : $t("Day"),
                  ])
                }}
                {{ $t("Free") }}</span
              >
            </vs-row>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.bg-card-header:hover .bg-grey-light {
  background-color: rgb(114, 103, 240) !important;
}
.vs-icon {
  vertical-align: top;
}
</style>
<script>
import { accMixin } from "@/mixins/mixins.js";

export default {
  mixins: [accMixin],
  data() {
    return {
      plansOptions: [],
    };
  },
  computed: {
    accPlanData: function () {
      return this.$store.state.acc.current_acc.accPlan;
    },
    currentAcc: function () {
      return this.$store.state.acc.current_acc;
    },
    currentAccPlan: function () {
      return this.$store.state.acc.current_acc.accPlan.plan;
    },
    planInTrial: function () {
      var now = new Date();
      var endTrial = new Date(
        this.$store.state.acc.current_acc.accPlan.endTrialDate
      );
      return endTrial.getTime() >= now.getTime();
    },
    trialElegible: function () {
      return this.$store.state.acc.current_acc.accPlan.trialDays > 0 ||
        this.$store.state.acc.current_acc.accPlan.startTrialDate === null
        ? true
        : false;
    },
    paymentMethodPending: function () {
      return this.$store.state.acc.current_acc.accPlan.paymentStatus === 2;
    },
    accWhitelabel() {
      return this.$store.state.acc.current_acc.whitelabel;
    },
  },
  methods: {
    getPlans() {
      var self = this;
      //fetchGetPlans
      const payload = {
        product: "5e4c20ee2767d32b33eaa12e",
        whitelabel: this.accWhitelabel,
        notify: this.$vs.notify,
      };
      this.$store
        .dispatch("auth/fetchGetPlans", payload)
        .then((result) => {
          for (var i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].listEditing)
              self.plansOptions.push(result.data.data[i]);
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t("Error"),
            text: error.message || error.data[0].msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  mounted() {
    this.getPlans();
  },
};
</script>